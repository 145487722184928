import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm,Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import FileInput from './FileInput';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const NewsForm = ({ news }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { control, handleSubmit, register, getValues, setValue ,formState:{errors}} = useForm({ defaultValues: news });
  const [date, setDate] = useState(news?.data ? new Date(news.data) : new Date());
  const textFields = [
    { value: news ? news.titolo : "", label: 'titolo',validation:{required:true} },
  ];
  const textAreaFields = [
    { value: news ? news.descrizione : "", label: 'descrizione',validation:{required:true} },
  ]
  const imageFields = [news ? news.foto1 : "", news ? news.foto2 : "", news ? news.foto3 : ""];
  const handleDelete = async () =>{
    // delete request
    const response = await fetch(`/api/admin/archivio_news/${news.id}`,{
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: new FormData()
    })

    if (response.ok){
      alert("oggetto eliminato correttamente")
      navigate('/admin')
    }else{
      alert("errore eliminazione oggetto")
    }
  }
  useEffect(() =>{
    setValue("data", date?.toISOString().replace("Z","+00:00"))
  },[date])
  const onSubmit = (data) => {
    console.log(data);
    if (JSON.stringify(data) === JSON.stringify(news)) {
      alert('Form data is equal to default values. Submission prevented.');
      return;
    }
    
    const formData = new FormData();
    if (data.foto1 && data.foto1 instanceof File) {
      formData.append("foto1", data.foto1);
    }
    if (data.foto2 && data.foto2 instanceof File) {
      formData.append("foto2", data.foto2);
    }
    if (data.foto3 && data.foto3 instanceof File) {
      formData.append("foto3", data.foto3);
    }
    if (data.allegato && data.allegato instanceof File) {
      formData.append("allegato", data.allegato);
    }
    
    console.log(data)
    data = {
      ...data,
      foto1: data.foto1 && data.foto1 instanceof File ? `/assets/images/news/${data.foto1.name}` : news?.foto1 ? news.foto1 : "",
      foto2: data.foto2 && data.foto2 instanceof File ? `/assets/images/news/${data.foto2.name}` : news?.foto2 ? news.foto2 : "",
      foto3: data.foto3 && data.foto3 instanceof File ? `/assets/images/news/${data.foto3.name}` : news?.foto3 ? news.foto3 : "",
      allegato: data.allegato && data.allegato instanceof File ? `/assets/images/news/${data.allegato.name}` : news?.allegato ? news.allegato : ""
    };
    
    formData.append("data", JSON.stringify(data));
    
    let url = "/api/admin/archivio_news";
    let method = "POST";
    
    if (data && data.id) {
      url = `/api/admin/archivio_news/${data.id}`;
      method = "PATCH";
    }
    fetch(url, {
      method: method,
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: formData
    })
      .then(response => {
        if (response.ok){
          let str_resp = method === "PATCH" ? "modifica avvenuta correttamente" : "creazione avvenuta correttamente"
          alert(str_resp)
          navigate("/admin")
      }else if(response.status === 401){
        alert("sessione interrotta, rieffettuare il login")
        navigate("/login")
      } 
      else{
        alert("errore : " + response.json())
      }
    })
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
    {textFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <input type="text" className="form-control" {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}
    {textAreaFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <textarea className="form-control" {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}

      <div>
      <label htmlFor="data" className="form-label mt-3">data:</label>
      <DatePicker
        selected={date}
        onChange={(date) => setDate(date)}
        showTimeSelect
        dateFormat="Pp"
      /></div>
      <div>
        {imageFields.map((img, index) => (
          <FileInput key={index} fieldName={`foto${index + 1}`} filePath={img ? img : ""} control={control} />
        ))}

        <FileInput fieldName="allegato" filePath={news ? news.allegato : ""} control={control} />
      </div>

      <button type="submit" className="btn btn-primary mt-3">Save</button>
    </form>
     <div>
     {news && <button  className="btn btn-primary m-3" onClick={handleDelete}>Elimina</button>}
   </div>
   </>
  );
};

const NewsManager = ({ idNews }) => {
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState(null);
  useEffect(() => {
    async function fetchData() {
      let newsResponse = null;
      let newsData = null;
      if (idNews !== null){
        newsResponse = await fetch(`/api/archivio_news/${idNews}`);
      }

      if (newsResponse!== null && !newsResponse.ok) {
        console.log('error');
        return;
      }
      if (newsResponse !== null){
        newsData = await newsResponse.json();
      }
    return [newsData];
    }

    fetchData().then((data) =>{
      console.log(data[0])
      setNews(data[0]);
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, [,idNews]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading style-center">
        <div className="section-heading__title mb-50" >
          <h2>{news !== null ? "modifica news" : "inserisci nuova news"}</h2>
        </div>
      </div>
    <div className="row align-items-center justify-content-center mb-50">
    <div className="col-1">
        <Link to="/admin">
            <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
            </button>
        </Link>
    </div>
      <div className="col-8 col-lg-6 section-bg ">
        <NewsForm news={news}/>
       </div>
       </div>
       </section> 
  );
};

export default NewsManager;
