import { useEffect,useState } from "react";
import { Link, useParams } from "react-router-dom";
import Preloader from "../helper/Preloader";
import Slider from "react-slick";
import Comment from "./Comment";
import CommentForm from "./CommentForm";
const BlogDetails = () => {
  const formLayout = {
    name:'blog',title:"Commenta un blog e ti risponderemo via mail",layout:[
        {fieldName:'autore',fieldDimensions:12,fieldLabel:'Nome e cognome',fieldValidation:{required:true},fieldType:'text'},
        {fieldName:'email',fieldDimensions:6,fieldLabel:'Indirizzo email',fieldValidation:{required:true},fieldType:'email'},
        {fieldName:'contenuto',fieldDimensions:12,fieldLabel:'Commento',fieldValidation:{required:true},fieldType:'textarea'},
    ]
}   
  const blog_id = useParams().id
  const [blog,setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [images,setImages] = useState([]);
  useEffect(() => {
    fetch(`/api/blogs/${blog_id}`)
      .then((response) =>{
        if (!response.ok){
          throw new Error('errore di rete');
        }
        return response.json()
      })
      .then((data) =>{
        setBlog(data);
        setLoading(false);
        setImages([data.foto1, data.foto2, data.foto3].filter(i => i !== null));
      })
      .catch((error) =>{
        setError(error);
        setLoading(false);
      })
  },[]);
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            <i className="las la-arrow-right m-10" />
        </button>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            <i className="las la-arrow-left m-10" />
        </button>
    );
}

const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [],
};

  if (loading){
    return <Preloader></Preloader>
  }
  if (error){
    return <div>{error.toString()}</div>
  }
  return (
    <>
      {/* Blog Details Section */}
      <section className="blog-details padding-y-120 position-relative overflow-hidden">
        <div className="container container-two">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {/* blog details top Start */}
              <div className="blog-details-top mb-64 ">
                <div className="blog-details-top__info flx-align gap-3 mb-4 ">
                  <div className="col-1">
                  <Link to="/blog">
                      <button type="button" className="btn-new w-100 pill">
                          <i className="fa fa-arrow-left" style={{'color':'yellow'}}></i>
                      </button>
                        </Link>
                  </div>
                  <div className="blog-details-top__thumb flx-align gap-2 col-6">
                    <i className="las la-user" />
                    <span className="text-heading fw-500">{blog.autore}</span>
                  </div>
                  <span className="blog-details-top__date flx-align gap-2 col-2">
                    <i className="las la-calendar"></i>
                    <span className="text-heading fw-500">{blog.data}</span>
                  </span>
                  </div>
                <h2 className="blog-details-top__title mb-4 text-capitalize text-center">
                  {blog.titolo}
                </h2>
              </div>
              {/* blog details top End */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {/* blog details content Start */}
              <div className="blog-details-content ">
                <div className="row align-items-center justify-content-center mb-5">
              {images.length > 1 ? (
                <div className="col-8 col-lg-6 text-center">
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <img src={process.env.PUBLIC_URL + img} alt={`slide ${index + 1}`} className="img-fluid rounded" />
                        ))}
                    </Slider>
                </div>
            ) : (
                <div className="col-10 col-lg-8 text-center">
                    <img src={process.env.PUBLIC_URL + blog.foto1} alt="" className="img-fluid rounded " />
                </div>
            )}</div>
                <p className="blog-details-content__desc mb-40">
                  {blog.descrizione}
                </p>
                {/* Quote Text Start */}
                {/* Quote Text Ebd */}
                {/* Post Tag & Share Start */}
                <div className="flx-between gap-2 mb-40 mt-40">
                  <div className="post-tag flx-align gap-3">
                    <span className="post-tag__text text-heading fw-500">
                      Tag:{" "}
                    </span>
                    {blog.tag !== null && 
                    <ul className="post-tag__list flx-align gap-2">
                      <li className="post-tag__item">
                        <Link
                          to="/blog"
                          className="post-tag__link font-14 text-heading pill fw-500"
                        >
                          {blog.tag.nome}
                        </Link>
                      </li>
                    </ul>}
                  </div>
                  <div className="socail-share flx-align gap-3">
                    <span className="socail-share__text text-heading fw-500">
                      Share On:{" "}
                    </span>
                    <ul className="social-icon-list colorful-style">
                      <li className="social-icon-list__item">
                        <Link
                          to="https://www.facebook.com"
                          className="social-icon-list__link text-heading font-16 flex-center"
                        >
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li className="social-icon-list__item">
                        <Link
                          to="https://www.twitter.com"
                          className="social-icon-list__link text-heading font-16 flex-center"
                        >
                          {" "}
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li className="social-icon-list__item">
                        <Link
                          to="https://www.google.com"
                          className="social-icon-list__link text-heading font-16 flex-center"
                        >
                          {" "}
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Post Tag & Share End */}
                {/* Advisor content Start */}
                {/* <div className="advisor p-3 p-lg-4 mt-40 mb-64 section-bg d-flex flex-sm-row flex-column align-items-start gap-md-4 gap-3">
                  <div className="advisor__thumb flex-shrink-0">
                    <img src="assets/images/thumbs/advisor-img.png" alt="" />
                  </div>
                  <div className="advisor__content flex-grow-1">
                    <h6 className="advisor__name font-18 mb-2">Brooklyn Simmons</h6>
                    <span className="advisor__designation text-heading mb-3 fw-500">
                      Chief Advisor{" "}
                    </span>
                    <p className="advisor__desc">
                      Lorem ipsum dolor sit amet consectetur. Nec nunc pellentesque
                      massa pretium. Quam sapien nec venenatis vivamus sed cras
                      faucibus.
                    </p>
                  </div>
                </div> */}
                {/* Advisor content End */}
                {/* Comment Form Start */}
                <CommentForm name={formLayout.name} title={formLayout.title} layout={formLayout.layout} parent_id={blog.id}/>
                {/* Comment Form End */}
                {/* Comment Start */}
                <div className="comment mt-64 mb-64">
                  <h5 className="mb-32">{blog.commenti.length} Comments</h5>
                  <ul className="comment-list">
                  {blog.commenti.map((commento,index) =>{
                    return <Comment comment={commento}/>
                  })}  
                  </ul>
                </div>
                {/* Comment End */}
              </div>
              {/* blog details content End*/}
            </div>
          </div>
        </div>
      </section>
    </>

  );
}

export default BlogDetails;