import { Helmet } from "react-helmet"
import FooterOne from "../components/FooterOne"
import HeaderOne from "../components/HeaderOne"
import Preloader from "../helper/Preloader"

const About = () =>{
    return (
        <>
        <Helmet>
            <meta name="description" content="Lediamoluce è un’azienda in grado di offrire soluzioni personalizzate per le esigenze specifiche di clienti che operano come rivenditori o come partite iva."></meta>
        </Helmet>
        <section className="container mw-100 position-relative z-index-1 overflow-hidden">
       
        <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2 className="fw-bold color-title-new">Illuminazione led per tutte le esigenze</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center text-sm-start p-3">
            <div className="col-sm-10 col-12 text-left mb-5">
                <h2 className="fs-2">Soluzioni personalizzate</h2>
                <p className="fs-5">Grazie alla <span className="fw-bold">vasta gamma di prodotti</span> presenti in catalogo, che includono <span className="fw-bold">illuminazione a led</span> per lo sport, l'industria, l’arredo urbano, l’architettura di interni ed esterni e tutti i settori di applicazione dell’illuminazione led, siamo in grado di soddisfare tutte le richieste e, contestualmente, garantire un notevole risparmio energetico.</p>
            </div>
            <div className="col-sm-10  col-12 text-left mt-5">
            <h2 className="fs-2">Prezzo competitivo perchè direttamente dal produttore</h2>
            <p className="fs-5">I nostri prodotti sono realizzati con materiali di <span className="fw-bold">alta qualità</span> e sono progettati per garantire <span className="fw-bold">prestazioni ottimali</span> e <span  className="fw-bold">lunga durata</span> oltre alla notevole competitività di listino poiché riforniti direttamente dai produttori.
            </p></div>
            <div className="col-sm-10  col-12 text-left mt-5">
            <h2 className="fs-2">Notevole esperienza nel settore dell’illuminazione outdoor e indoor</h2>
            <p className="fs-5">Con la presenza di <span className="fw-bold">oltre un decennio nel settore</span> dell'illuminazione, abbiamo acquisito un <span className="fw-bold">know how importante</span> sia sulle soluzioni tecnologiche più efficienti che sui sistemi di installazione, skill che garantiscono una consulenza di alto livello e un’<span className="fw-bold">assistenza</span> di grande qualità.
            </p>
            <p className="fs-5">Se sei alla ricerca di soluzioni di illuminazione a LED per il risparmio energetico, contattaci per una consulenza personalizzata e un preventivo gratuito. Ti aiutiamo a trovare la soluzione migliore per le tue esigenze.
            </p>
            </div>
            <div className="col-sm-10  col-12 text-left mt-5">
            <p className="fs-5">Commercializziamo i nostri prodotti LED senza intermediari sia del mercato italiano che di quello europeo e intercontinentale. Ottimizziamo i costi e offiamo maggiore personalizzazione rispetto le esigenze del cliente.</p></div>
            <div className="col-sm-10  col-12 text-left mt-5"><h2 className="fs-2">Lavoriamo su commissione, ci rivolgiamo esclusivamente a rivenditori e partite iva.</h2></div>
        </div></section></>
    )
}

const AboutPage = ()=>{
    return (
        <section className="change-gradient">
            <Preloader /> 
            <HeaderOne />
            <About />
            <FooterOne />
        </section>
    )
}

export default AboutPage;