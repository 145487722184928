import Slider from 'react-slick';

const ArchivioInstallazioni = ({installazioni}) => {

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-right m-10" />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-left m-10" />
      </button>
    );
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [],
  };

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
      <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2 className='color-title-new'>Archivio Installazioni</h2>
          </div>
        </div>
      <div className="row align-items-center justify-content-center mb-50">
        <div className="col-8 col-lg-6 text-center section-bg ">
          <Slider {...settings}>
            {installazioni.map((installazione, index) => (
              <div key={index} className='col-8 text-center'>
                <img src={process.env.PUBLIC_URL + installazione.foto1} alt={`slide ${index + 1}`} className="img-fluid rounded custom-img " />
                <h3 className='mt-40'>{installazione.titolo}</h3>
                {installazione.descrizione !== null ? <p className='mb-40'>{installazione.descrizione}</p> : <></>}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ArchivioInstallazioni;
