import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Pagination from "./Pagination";

const BlogCard = ({blog}) =>{
  return (
    <div className="col-lg-6 col-sm-6">
    <div className="post-item">
      <div className="post-item__thumb">
        <Link to={`/dettagli_blog/${blog.id}`} className="link">
          <img
            src={process.env.PUBLIC_URL + blog.foto1}
            className="cover-img"
            alt=""
          />
        </Link>
      </div>
      <div className="post-item__content">
        <div className="post-item__top flx-align">
          {blog.tag !== null ? 
          <Link
            to={`/dettagli_blog/${blog.id}`}
            className="post-item__tag pill font-14 text-heading fw-500 hover-text-main"
          >
            {blog.tag.nome}
          </Link> : <></>}
          <div className="post-item__date font-14 flx-align gap-2 font-14 text-heading fw-500">
            <span className="icon">
              <img
                src="assets/images/icons/calendar.svg"
                alt=""
                className="white-version"
              />
              <img
                src="assets/images/icons/calendar-white.svg"
                alt=""
                className="dark-version"
              />
            </span>
            <span className="text">{blog.data}</span>
          </div>
        </div>
        <h5 className="post-item__title">
          <Link to={`/dettagli_blog/${blog.id}`}
  className="link">
            {blog.titolo}
          </Link>
        </h5>
        <Link
          to={`/dettagli_blog/${blog.id}`}
          className="btn btn-outline-light pill fw-600"
        >
          Dettagli{" "}
        </Link>
      </div>
    </div>
  </div>)
}

const Blog = ({blogs,blogtags}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredBlogs,setFilteredBlogs] = useState(blogs);
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [selectedBlogTag,setSelectedBlogTag] = useState("");

  const handleBlogTagChange = (event) =>{
    setSelectedBlogTag(event.target.value);
  }

  useEffect(()=>{
    if (selectedBlogTag == ""){
      setFilteredBlogs(blogs);
    }else{
      setFilteredBlogs(blogs.filter((blog) => {return (blog.tag !== null && blog.tag.nome === selectedBlogTag)}))
    }
  },[selectedBlogTag])
  
  const blogsPerPage = 2;
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);
  
  return (
    <section className="blog padding-y-120  position-relative z-index-1 overflow-hidden ">
      <div className="container container-two ">
      <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2 className="color-title-new">Blog</h2>
          </div>
        </div>
        <div className="row gy-4 justify-content-center mb-5">
          <div className="col-6">
            <span>Ricerca per tag</span>
            <select id="blogtag-select" className='common-input border-gray-five common-input--withLeftIcon' value={selectedBlogTag} onChange={handleBlogTagChange}>
              <option value="">Tutti</option>
              {blogtags.map((blogtag, index) => (
                <option key={index} value={blogtag.nome}>
                  {blogtag.nome}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row gy-4 ">
            {currentBlogs.map((blog) =>{return <BlogCard blog={blog}></BlogCard>})}
        </div>
        {/* Pagination Start */}
        <div className="col-12">
        <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
          </div>
        {/* Pagination End */}
      </div>
    </section>
  );
}

export default Blog;