import { useEffect,useState } from "react";
import { Link, useParams } from "react-router-dom";
import Preloader from "../helper/Preloader";
import Slider from "react-slick";
import Comment from "./Comment";
const NewsDetails = () => {
  const news_id = useParams().id
  const [news,setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [images,setImages] = useState([]);
  useEffect(() => {
    fetch(`/api/archivio_news/${news_id}`)
      .then((response) =>{
        if (!response.ok){
          throw new Error('errore di rete');
        }
        return response.json()
      })
      .then((data) =>{
        setNews(data);
        setLoading(false);
        setImages([data.foto1, data.foto2, data.foto3].filter(i => i !== null));
      })
      .catch((error) =>{
        setError(error);
        setLoading(false);
      })
  },[]);
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            <i className="las la-arrow-right m-10" />
        </button>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            <i className="las la-arrow-left m-10" />
        </button>
    );
}

const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [],
};

  if (loading){
    return <Preloader></Preloader>
  }
  if (error){
    return <div>{error.toString()}</div>
  }
  return (
    <>
      {/* Blog Details Section */}
      <section className="blog-details padding-y-120 position-relative overflow-hidden">
        <div className="container container-two">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {/* blog details top Start */}
              <div className="blog-details-top mb-64 ">
                <div className="blog-details-top__info flx-align gap-3 mb-4 ">
                  <div className="col-1">
                  <Link to="/news">
                      <button type="button" className="btn-new w-100 pill">
                          <i className="fa fa-arrow-left" style={{'color':'yellow'}}></i>
                      </button>
                        </Link>
                  </div>
                  <span className="blog-details-top__date flx-align gap-2 col-2">
                    <i className="las la-calendar"></i>
                    <span className="text-heading fw-500">{news.data}</span>
                  </span>
                  </div>
                <h2 className="blog-details-top__title mb-4 text-capitalize text-center">
                  {news.titolo}
                </h2>
              </div>
              {/* blog details top End */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {/* blog details content Start */}
              <div className="blog-details-content ">
                <div className="row align-items-center justify-content-center mb-5">
              {images.length > 1 ? (
                <div className="col-8 col-lg-6 text-center">
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <img src={process.env.PUBLIC_URL + img} alt={`slide ${index + 1}`} className="img-fluid rounded" />
                        ))}
                    </Slider>
                </div>
            ) : (
                <div className="col-10 col-lg-8 text-center">
                    <img src={process.env.PUBLIC_URL + news.foto1} alt="" className="img-fluid rounded " />
                </div>
            )}</div>
                <p className="blog-details-content__desc mb-40">
                  <div >{news.descrizione}</div>
                </p>
                {/* Quote Text Start */}
                {/* Quote Text Ebd */}
                {/* Post Tag & Share Start */}
                <div className="flx-between gap-2 mb-40 mt-40 justify-content-center">
                {news.allegato !== null && (
                                <>
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            className="btn-new w-100 justify-content-center align-items-center gap-2 pill"
                                        >
                                            <a href={process.env.PUBLIC_URL + news.allegato} download={true} style={{'color':'yellow'}}>
                                            Scarica allegato
                                            </a>
                                        </button>
                                    </div>
                                </>
                            )}   
                </div>
                
              </div>
              {/* blog details content End*/}
            </div>
          </div>
        </div>
      </section>
    </>

  );
}

export default NewsDetails;