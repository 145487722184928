import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm,Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import { useNavigate } from 'react-router-dom';
const BlogTagForm = ({ blogTag }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { control, handleSubmit, register, getValues, setValue,formState:{errors} } = useForm({ defaultValues: blogTag });
  
  const textFields = [
    { value: blogTag ? blogTag.nome : "", label: 'nome',validation:{required:true} },
  ];
  const handleDelete = async () =>{
      // delete request
      const response = await fetch(`/api/admin/blogtags/${blogTag.id}`,{
        method: "DELETE",
        headers: {
          "Authorization": "Bearer " + auth.token,
        },
        body: new FormData()
      })

      if (response.ok){
        alert("oggetto eliminato correttamente")
        navigate("/admin")
      }else{
        alert("errore eliminazione oggetto")
      }
  }
  const onSubmit = (data) => {
    if (JSON.stringify(data) === JSON.stringify(blogTag)) {
      alert('Dati non modificati, aggiornamento evitato');
      return;
    }
    
    const formData = new FormData();
    
    formData.append("data", JSON.stringify(data));
    
    let url = "/api/admin/blogtags";
    let method = "POST";
    
    if (data && data.id) {
      url = `/api/admin/blogtags/${data.id}`;
      method = "PATCH";
    }
    fetch(url, {
      method: method,
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: formData
    })
      .then(response => {
    if (response.ok){
      let str_resp = method === "PATCH" ? "modifica avvenuta correttamente" : "creazione avvenuta correttamente"
      alert(str_resp)
      navigate("/admin")
    }else if(response.status === 401){
      alert("sessione interrotta, rieffettuare il login")
      navigate("/login")
    }else{
      alert("errore : " + response.json())
    }
  })
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
    {textFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <input type="text" className="form-control" {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}
      
      <button type="submit" className="btn btn-primary mt-3">Save</button>
    </form>
    <div>
    {blogTag && <button  className="btn btn-primary m-3" onClick={handleDelete}>Elimina</button>}
  </div></>
  );
};

const BlogTagManager = ({ idBlogTag }) => {
  const [loading, setLoading] = useState(true);
  const [blogTag, setBlogTag] = useState(null);
  useEffect(() => {
    async function fetchData() {
      let blogTagResponse = null;
      let blogTagData = null;
      if (idBlogTag !== null){
        blogTagResponse = await fetch(`/api/blogtags/${idBlogTag}`);
      }
      if (!blogTagResponse.ok) {
        console.log('error');
        return;
      }
      if (blogTagResponse !== null){
        blogTagData = await blogTagResponse.json();
      }
    return [blogTagData];
    }

    fetchData().then((data) =>{
        setBlogTag(data[0])
        setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, [,idBlogTag]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading style-center">
        <div className="section-heading__title mb-50" >
          <h2>{blogTag !== null ? "modifica tag" : "inserisci nuovo tag"}</h2>
        </div>
      </div>
    <div className="row align-items-center justify-content-center mb-50">
    <div className="col-1">
        <Link to="/admin">
            <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
            </button>
        </Link>
    </div>
      <div className="col-8 col-lg-6 section-bg ">
        <BlogTagForm blogTag={blogTag}/>
       </div>
       </div>
       </section> 
  );
};

export default BlogTagManager;
