import HeaderOne from "../components/HeaderOne";
import Preloader from "../helper/Preloader";
import FooterOne from "../components/FooterOne";
import { useNavigate } from "react-router-dom";
const ConsulenzaEnergetica = () =>{
    const navigate = useNavigate();
    const goToContactPage = () =>{
        navigate('/contatti')
    }
    return (
        <section className="container mw-100 position-relative z-index-1 overflow-hidden">
        <div className="section-heading style-center">
          <div className="section-heading__title mb-50 " >
            <h2 className="color-title-new">Consulenza Energetica</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center mb-50">
          <div className="col-11 col-sm-10 text-left">
                <h2 className="fs-2 fw-bold">Progettazione per illuminazione pubblica e privata: maggiore potenza e più economia energetica</h2>
                <div className="fs-5">Desiderate maggiore potenza e sicurezza? L’uso di sistemi di illuminazione realizzati con tecnologie di nuova generazione a economia energetica, consente la riduzione dei consumi nel rispetto di normative e leggi in vigore.</div>
                <div className="fs-5">Il Consulente Energetico progetta e gestisce i lavori di illuminazione pubblica e privata in grado di soddisfare tutte le prescrizioni di sicurezza, risparmio energetico, efficienza e durata nel tempo.</div>
            </div>
        </div>
        <div className="row align-items-center justify-content-center mt-5">
          <div className="col-11 col-sm-10">
                <h2 className="fw-bold text-left fs-2">Il nostro intervento di consulenza e realizzazione di progetti è richiesto in opere di:</h2>
                <ul className="list-circle fs-5 text-left"> 
                    <li>Aggiornamento impianti di illuminazione pubblica e illuminazione privata, impianti di illuminazione esterni e interni a garanzia di un miglioramento delle prestazioni. Incremento di risultati in termini di efficienza energetica in conformità con le disposizioni normative regionali e nazionali.</li>
                    <li>Illuminazione stradale Smart City realizzata con l’obiettivo di ottenere un sistema intelligente di gestione degli impianti con la corretta Potenza di Illuminazione e con l’Efficienza energetica</li>
                    <li>Illuminazione esterna per piazze, parcheggi e viali.</li>
                    <li>Illuminazione esterna e interna di capannoni industriali, acciaierie, ATEX Zone 1 e 2.</li>
                    <li>Illuminazioni esterna di impianti sportivi, campi da calcetto a 5/7, calcio a 11, tennis e padel, campi di atletica e altri spazi di allenamento.</li>
                </ul>
            </div>
        </div>
        <div className="row align-items-center justify-content-center mb-50">
          <div className="col-11 col-sm-10 mt-5 ">
                <h2 className="fw-bold mb-5 mt-5 text-left fs-2">Richiedi una prima valutazione gratuita e personalizzata</h2>
                    <div className="fw-bold fs-5 text-left">Oggi la sensibilità delle persone e delle istituzioni riguardo le tematiche di risparmio energetico e riduzione di inquinamento luminoso che il led può garantire, è in aumento. Così come continua ad aumentare la volontà di ridurre i consumi e di migliorare l’efficienza energetica e di illuminazione degli impianti pubblici e privati.
                <p className="fs-5 mt-3 text-left" style={{marginBottom:50,}}>Se devi realizzare o adeguare il tuo impianto di illuminazione pubblica o privata, chiedi un consiglio al nostro consulente energetico illustrandoci le tue esigenze e inviando i dati tecnici, disegni e documenti utili ad elaborare una strategia d’intervento</p>
                <div className="text-center">
                <button
                    type="button"
                    className="btn-new text-center fs-3 mb-3 gap-2 pill px-sm-5 "
                    onClick={goToContactPage}>
                        <div className="fw-bold fs-5 m-3" style={{'color':'yellow'}}> Chiedi una consulenza senza impegno!</div>
                    </button>
                    </div>
                </div>
                
            </div>
        </div>
        </section>
    )
};

const ConsulenzaEnergeticaPage = () =>{
    return (
        <section className="change-gradient">
        <Preloader />
        <HeaderOne />
        <ConsulenzaEnergetica />
        <FooterOne />
        </section>
    )
}
export default ConsulenzaEnergeticaPage;