import { useState,useEffect } from "react";
import { Link,useLocation } from "react-router-dom";
import data from '../data.json';
import ProductCard from "./ProductCard";
import {useForm} from "react-hook-form";
import Pagination from "./Pagination";
const BrandSelect = ({brands}) => {
  const [selectedBrand, setSelectedBrand] = useState('');

  const handleChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  return (
    <div>
      <select id="brand-select" className='common-input border-gray-five common-input--withLeftIcon' value={selectedBrand} onChange={handleChange}>
        <option value="">Tutti</option>
        {brands.map((brand, index) => (
          <option key={index} value={brand}>
            {brand}
          </option>
        ))}
      </select>
    </div>
  );
};


const AllProduct = ({brands,categories}) => {
  let products = categories.flatMap(category => category.categorie.flatMap(subcategory => subcategory.prodotti));
  
  const [activeButton, setActiveButton] = useState("grid-view");
  const [filter, setFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);
  const [activeSubAccordionIndex, setActiveSubAccordionIndex] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(categories[0].categorie[0].prodotti);
  const [tag,setTag] = useState("")
  
  const location = useLocation();
  const { searchQuery } = location.state || '';
  // const { searchParam } = location.search;
  useEffect(()=>{setTag(searchQuery)},[searchQuery])
  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    const categoryId = params.get('categoria');
    if(categoryId){
      const categoryElement = document.getElementById(`categoria-${categoryId}`)
      if(categoryElement){
        categoryElement.click();
      }
    }
  },[location])
  
  // setTag(searchQuery);
  const handleSelectChange = (event) => {
    console.log(event.target.value)
    setSelectedBrand(event.target.value);
  };
  const handleTagChange = (event) =>{
    setTag(event.target.value)
  }

  useEffect(() => {
    let products = [];
    if (activeAccordionIndex !== null) {
      const selectedCategory = categories[activeAccordionIndex];
      products = selectedCategory.categorie.flatMap(subcategory => subcategory.prodotti);
      if (activeSubAccordionIndex !== null) {
        const selectedSubcategory = selectedCategory.categorie[activeSubAccordionIndex];
        products = selectedSubcategory.prodotti;
      }
    }else{
      products = categories.flatMap(category => category.categorie.flatMap(subcategory => subcategory.prodotti));
    }
    if (selectedBrand) {
      products = products.filter(product => {if (product.marca !== null){return product.marca.id == selectedBrand}else{return false} });
    }
    if (tag && tag.length > 2){
      console.log(tag)
      products = products.filter(product =>{return product.nome.toLowerCase().includes(tag.toLowerCase())})
    }
    setFilteredProducts(products);
  }, [activeAccordionIndex,activeSubAccordionIndex, selectedBrand, tag,categories]);

  const handleAccordionClick = (index) => {
    setActiveAccordionIndex(activeAccordionIndex === index ? null : index);
    setActiveSubAccordionIndex(null);
  };

  const handleSubAccordionClick = (index) =>{
    setActiveSubAccordionIndex(activeSubAccordionIndex === index ? null : index);
  }


  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleFilter = () => {
    setFilter(!filter);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber <= totalPages){
      setCurrentPage(pageNumber);
  }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  return (
    <section className={`all-product padding-y-120 ${activeButton === "list-view" ? "list-view" : ""}`}>
      <div className="container container-two">
      <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2 className="color-title-new">Catalogo Prodotti</h2>
          </div>
        </div>
        <div className="row">
            <div className="row gy-3 flx-between align-items-center">
              <div className="col-5 col-lg-6">
                <div className="flx-between">
                  <label htmlFor="tag" className="form-label font-16">
                    Cerca prodotti
                  </label>
                </div>
                <div className="position-relative">
                  <input
                    type="text"
                    className="common-input border-gray-five common-input--withLeftIcon"
                    id="tag"
                    placeholder="Parola chiave..."
                    value={tag}
                    onChange={handleTagChange}
                  />
                  <span className="input-icon input-icon--left">
                    <img src="assets/images/icons/search-two.svg" alt="" />
                  </span>
                  
                </div>
              </div>
              <div className="col-5 col-lg-6">
                <div>
                <label className='form-label font-16' htmlFor="brand-select">Brand</label>
                </div>
                <select id="brand-select" className='common-input border-gray-five common-input--withLeftIcon position-relative' onChange={handleSelectChange}>
                    <option value="">Tutti</option>
                    {brands.map((brand, index) => (
                      <option key={index} value={brand.id}>
                        {brand.nome}
                      </option>
                    ))}
                  </select>
              </div>
              <div className="col-2 text-center align-self-end mb-2 justify-content-end">
                   <button tytpe="button" className="btn-new list-grid__button sidebar-btn text-body d-lg-none d-flex" onClick={handleFilter}>
                    <i className="las la-bars" />
                  </button>
                </div>
              <div className="line-separator"></div>
            </div>
          
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <div className={`filter-sidebar ${filter && "show"}`}>
              <button
                type="button"
                className="btn-new filter-sidebar__close p-2 position-absolute end-0 top-0 z-index-1 text-body hover-text-main font-20 d-lg-none d-block"
                onClick={handleFilter}>
                <i className="las la-times" />
              </button>
              <div className="filter-sidebar__item">
                <button
                  type="button"
                  className="btn-new filter-sidebar__button font-16 text-capitalize fw-500"
                >
                  Categorie
                </button>
                <div className="filter-sidebar__content">
                  <div className="accordion-menu">
                      {categories.map((category, index) => (
                        <div key={index} className="filter-sidebar-list__item">
                          <button id={`categoria-${category.id}`} onClick={() => handleAccordionClick(index)} className={activeAccordionIndex === index ? 'filter-sidebar-list__item__button active' : 'filter-sidebar-list__item__button'}>
                            {category.nome}
                          </button>
                          {activeAccordionIndex === index && (
                            <ul>
                              {category.categorie.map((subcategory, subIndex) => (
                                <li className="filter-sidebar-list__item" key={subIndex} >
                                  <button onClick={() =>handleSubAccordionClick(subIndex)} className={activeSubAccordionIndex === subIndex ? 'filter-sidebar-list__item__button active' : 'filter-sidebar-list__item__button'}>
                                  {subcategory.nome}</button></li>
                              ))}
                            </ul>
                            
                          )}
                          <div className="product-sidebar__top position-relative flx-between gap-1 col-12" />
                        </div>

                      ))}
                    </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-xl-9 col-lg-8">
          {activeAccordionIndex !== null && (
            <div className="text-center position-relative row">
              <div className="col-12 mb-40 fw-bold">
                Categorie &gt; {categories[activeAccordionIndex].nome}{" "}{activeSubAccordionIndex !== null && (<span>&gt;{" "}{categories[activeAccordionIndex].categorie[activeSubAccordionIndex].nome}</span>)}</div>
            {categories[activeAccordionIndex].descrizione !== "" &&
              ( <div className="col-12 mb-40"> {categories[activeAccordionIndex].descrizione.split(';').map((row) =>{return <p>{row}</p>})}</div>  
              )
            }
            </div>
          )}
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-product"
                role="tabpanel"
                aria-labelledby="pills-product-tab"
                tabIndex={0}
              >
                <div className="row gy-4 list-grid-wrapper">
                  {currentProducts.map(product => (<ProductCard product={product} />)).filter(()=>true)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
          <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllProduct;
