import { Helmet } from "react-helmet"
import FooterOne from "../components/FooterOne"
import HeaderOne from "../components/HeaderOne"
import Preloader from "../helper/Preloader"
import { useNavigate } from "react-router-dom"
const Rateizzazioni = () =>{
     const navigate = useNavigate();
        const goToContactPage = () =>{
            navigate('/contatti')
        }
    return (
        <>
        <Helmet>
            <meta name="description" content="Lediamoluce è un’azienda in grado di offrire soluzioni personalizzate per le esigenze specifiche di clienti che operano come rivenditori o come partite iva."></meta>
        </Helmet>
        <div className="container mw-100 position-relative section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2 className="fw-bold color-title-new">Rateizzazioni</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center text-sm-start">
            <div className="col-sm-10 col-12 text-left mb-3">
                <p className="fs-5 ">L’illuminazione professionale per gli impianti sportivi oggi è acquistabile anche attraverso una comoda rateizzazione a tasso zero!</p>
            </div>
            <div className="col-sm-10 col-12 text-left mt-2">
            <p className="fs-5">Lediamoluce.it offre questa opportunità a tutti i clienti che devono illuminare campi per le discipline sportive individuali o di gruppo, in particolare per campi da <span className="fw-bold">tennis, padel, calcetto, calcio a 7/8</span> e <span className="fw-bold">calcio a 11</span>.
            </p></div>
            <div className="col-sm-10  col-12 text-left mt-3">
            <p className="fs-5">L’<span className="fw-bold">Offerta Speciale</span> comprende il finanziamento diretto del 60% del costo dei proiettori.
            </p>
            </div>
            <div className="col-sm-10  col-12 text-left mt-3">
            <h2 className="fw-bold fs-2">Il piano rateale prevede 12 o 24 mensilità a tasso zero!</h2>
            </div>
            <div className="col-sm-10  col-12 text-left mt-3">
            <p className="fs-5 mb-3">Contattaci, saremo lieti di offrirti la soluzione su misura per le tue esigenze.</p>
            <div className="text-center">
                <button
                    type="button"
                    className="btn-new text-center fs-3 mb-3 gap-2 pill px-sm-5 "
                    onClick={goToContactPage}>
                        <div className="fw-bold fs-5 m-3" style={{'color':'yellow'}}> Chiedi una consulenza senza impegno!</div>
                    </button>
                    </div>
            </div>
        </div>
    </>
    )
}

const RateizzazioniPage = ()=>{
    return (
        <section className="change-gradient">
            <Preloader /> 
            <HeaderOne />
            <Rateizzazioni />
            <FooterOne />
        </section>
    )
}

export default RateizzazioniPage;