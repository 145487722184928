import {useState,useEffect} from "react";
import {Link} from 'react-router-dom';
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import BannerOne from "../components/BannerOne";
import PopularOne from "../components/PopularOne";
import BecomeSellerOne from "../components/BecomeSellerOne";
import FooterOne from "../components/FooterOne";
import ProductsOverview from "../components/ProductsOverview";
import ArchivioInstallazioni from "../components/ArchivioInstallazioni";
import ProductCard from "../components/ProductCard";
import {Helmet} from 'react-helmet';
import RateizzazioniBanner from '../components/RateizzazioniBanner';

const HomePage = () => {
  const [data,setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch('/api/macro_categories')
        .then((response) =>{
          if (!response.ok){
            throw new Error('errore di rete');
          }
          return response.json()
        })
        .then((data) =>{
          console.log(data)
          setData(data);
          setLoading(false);
        })
        .catch((error) =>{
          console.log(error)
          setError(error);
          setLoading(false);
        })
    },[]);

    function getCategories() {
      if (!loading && !error){
        // TODO aggiungere map della lista degli oggetti e inserirli
        let cards = data.map(c =>
          {
          return (
            <Link to={`/prodotti?categoria=${c.id}`} className="product-preview">
            <div className="product-container">
              <span >
                <img src={process.env.PUBLIC_URL + c.foto1} alt={c.nome} />
              </span>
              <h5 className="mt-3 mb-3 text-center fw-500">{c.nome}</h5>
            </div>
          </Link>
              );})
      return cards;
      }else{
        return []
      }
    }
    function getProductsOverview() {
      if (!loading && !error) {
        let cards = [];
        data.forEach(macrocategory => {
          macrocategory.categorie.forEach(category => {
            category.prodotti.forEach(product => {
              if (product.in_evidenza) {
                cards.push(<ProductCard product={product}></ProductCard>);
              }
            });
          });
        });
        return cards;
      } else {
        return [];
      }
    }
    
  return (
    <section className="change-gradient">
      <Helmet>
    <meta name="description" content="Scopri le migliori installazioni LED per illuminazione sportiva, illuminazione commerciale, illuminazione industriale, illuminazione urbana"/>
    <meta name="keywords" content="LED, illuminazione sportiva, illuminazione industriale, luci LED, installazioni LED, illuminazione urbana, illuminazione commerciale, " />
    </Helmet>
    {/* Preloader */}
    <Preloader />

    {/* HeaderOne */}
    <HeaderOne />

    {/* BannerOne */}
    <BannerOne />

    <ProductsOverview products={getProductsOverview()}/>
    {/* PopularOne */}
    <PopularOne  categories={getCategories()}/>
    
    {/* BecomeSellerOne */}
    <RateizzazioniBanner />

    {/* FooterOne */}
    <FooterOne />
    
  </section>

  );
};

export default HomePage;
