import { Link, useNavigate } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";
import { useAuth } from "../provider/authProvider";
import { useState,useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import logoWhite from "../assets/images/icons/lediamoluce.svg";

const Login = () => {
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [errorMessage,setErrorMessage] = useState(null)
  const {setToken} = useAuth();
  const captchaRef = useRef(null);
  const handleChangeEmail = (event) =>{
    setEmail(event.target.value)
  }
  const handleChangePassword = (event) =>{
    setPassword(event.target.value)
  }

  const navigate = useNavigate()
  const handleSubmit = async (event) =>{
    // if (!captchaRef.current.getValue()){
    //   alert("compilare il captcha")
    //   return 
    // }
      event.preventDefault();
      try{
        const response = await fetch('/api/auth/login',{
          method:'POST',
          body:JSON.stringify({email:email,password:password}),
          headers:{'Content-Type':'multipart/form-data'}
        })
        const json = await response.json()
        if (response.ok){
              setToken(json.auth_token)
              sessionStorage.setItem("token",json.auth_token)
              navigate("/admin")
            }else{
              setToken(null)
              sessionStorage.removeItem("token")
              setErrorMessage(json.message)
            }
          
        }catch (error){
        console.error("autentication failed:",error)
        setToken(null)
        sessionStorage.removeItem("token")
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }

  return (
    <>
      {/* ================================== Account Page Start =========================== */}
      <section className="account d-flex">
        <div className="account__right padding-y-120 flx-align">
         
          
          <div className="dark-light-mode">
            {/* Light Dark Mode */}
            <ThemeToggle />
          </div>
          <div className="account-content">
          <Link to="/" className="text-center m-0">
                    <img
                  src={logoWhite}
                  alt="" className="w-50 m-0 p-0 text-center"
                /></Link>     
            <h4 className="account-content__title mb-4 mt-4 text-capitalize text-center">
              Bentornato!
            </h4>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}{" "}
            <form onSubmit={handleSubmit}>
              <div className="row gy-4">
                <div className="col-12">
                  <label
                    htmlFor="email"
                    className="form-label mb-2 font-18 font-heading fw-600"
                  >
                    Email
                  </label>
                  <div className="position-relative">
                    <input
                      type="email"
                      className="common-input common-input--bg common-input--withIcon"
                      id="email"
                      placeholder="infoname@mail.com" onChange={handleChangeEmail}
                    />
                    <span className="input-icon">
                      <img src="assets/images/icons/envelope-icon.svg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <label
                    htmlFor="your-password"
                    className="form-label mb-2 font-18 font-heading fw-600"
                  >
                    Password
                  </label>
                  <div className="position-relative">
                    <input
                      type="password"
                      className="common-input common-input--bg common-input--withIcon"
                      id="your-password"
                      placeholder="6+ characters, 1 Capital letter"
                      onChange={handleChangePassword}
                    />
                    <span
                      className="input-icon toggle-password cursor-pointer"
                      id="#your-password"
                    >
                      <img src="assets/images/icons/lock-icon.svg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="flx-between gap-1">
                    <Link
                      to="#"
                      className="forgot-password text-decoration-underline text-main text-poppins font-14"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div className="col m-3">
                    <ReCAPTCHA
                    sitekey="6Ld0K5MqAAAAAIUZyqa44NUYxWslSdlw4tlJdMeX"
                    ref={captchaRef} />
                   </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-main btn-lg w-100 pill"
                  >
                    {" "}
                    Sign In
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* ================================== Account Page End =========================== */}
    </>
  );
};

export default Login;
