import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm,Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import FileInput from './FileInput';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BlogForm = ({ blog,blogtags }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { control, handleSubmit, register, getValues, setValue,formState:{errors} } = useForm({ defaultValues: blog });
  const [date, setDate] = useState(blog?.data ? new Date(blog.data) : new Date());
 
  const textFields = [
    { value: blog ? blog.titolo : "", label: 'titolo' ,validation:{required:true}},
    { value: blog ? blog.autore : "", label: 'autore' ,validation:{}},
  ];
  const textAreaFields = [
    { value: blog ? blog.descrizione : "", label: 'descrizione' ,validation:{}},
  ];


  const imageFields = [blog ? blog.foto1 : "", blog ? blog.foto2 : "", blog ? blog.foto3 : ""];
  useEffect(() =>{
    setValue("data", date?.toISOString().replace("Z","+00:00"))
  },[date])
  useEffect(()=>{
    setValue('tag_id', blog && blog.tag ? blog.tag.id : null);
  },[blog])
  const handleDelete = async () =>{
    // delete request
    const response = await fetch(`/api/admin/blogs/${blog.id}`,{
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: new FormData()
    })

    if (response.ok){
      alert("oggetto eliminato correttamente")
      navigate('/admin')
    }else{
      alert("errore eliminazione oggetto")
    }
  }
  const handleNewTag = () =>{
    
  }
  const onSubmit = (data) => {
    console.log(data);
    if (JSON.stringify(data) === JSON.stringify(blog)) {
      alert('Form data is equal to default values. Submission prevented.');
      return;
    }
    
    const formData = new FormData();
    if (data.foto1 && data.foto1 instanceof File) {
      formData.append("foto1", data.foto1);
    }
    if (data.foto2 && data.foto2 instanceof File) {
      formData.append("foto2", data.foto2);
    }
    if (data.foto3 && data.foto3 instanceof File) {
      formData.append("foto3", data.foto3);
    }
    
    data = {
      ...data,
      foto1: data.foto1 && data.foto1 instanceof File ? `/assets/images/blog/${data.foto1.name}` : blog?.foto1 ? blog.foto1 : "",
      foto2: data.foto2 && data.foto2 instanceof File ? `/assets/images/blog/${data.foto2.name}` : blog?.foto2 ? blog.foto2 : "",
      foto3: data.foto3 && data.foto3 instanceof File ? `/assets/images/blog/${data.foto3.name}` : blog?.foto3 ? blog.foto3 : "",
    };
    data['tag_id'] = data['tag_id'] == 'non specificato' ? null : parseInt(data['tag_id'],10)
    delete data['tag']
    delete data['commenti']
    formData.append("data", JSON.stringify(data));
    
    let url = "/api/admin/blogs";
    let method = "POST";
    
    if (data && data.id) {
      url = `/api/admin/blogs/${data.id}`;
      method = "PATCH";
    }
    fetch(url, {
      method: method,
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: formData
    })
      .then(response => {
    if (response.ok){
        let str_resp = method === "PATCH" ? "modifica avvenuta correttamente" : "creazione avvenuta correttamente"
        alert(str_resp)
        navigate("/admin")
    }else if(response.status === 401){
      alert("sessione interrotta, rieffettuare il login")
      navigate("/login")
    } 
    else{
      alert("errore : " + response.json())
    }
  })
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
    
    
    {textFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <input type="text" className={`form-control ${field.className}`} {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}
      {textAreaFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <textarea className="form-control" {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}
    
      <div>
      <label htmlFor="data" className="form-label mt-3">data:</label>
      <DatePicker
        selected={date}
        onChange={(date) => setDate(date)}
        showTimeSelect
        dateFormat="Pp"
      /></div>
      <div>
        {imageFields.map((img, index) => (
          <FileInput key={index} fieldName={`foto${index + 1}`} filePath={img ? img : ""} control={control} />
        ))}

        <label htmlFor="blogtag" className="form-label mt-3">tag</label>
            <Controller
              name="tag_id"
              control={control}
              render={({ field }) => (
                <>
                <select id="tag" className="form-control" {...field}>
                  <option value={null}>non specificato</option>
                  {blogtags.map(tag => (
                    <option key={tag.id} value={tag.id}>{tag.nome}</option>
                  ))}
                </select>
                </>
              )}
            />
      </div>

      <button type="submit" className="btn btn-primary mt-3">Save</button>
    </form>
     <div>
     {blog && <button  className="btn btn-primary m-3" onClick={handleDelete}>Elimina</button>}
   </div></>
  );
};

const BlogManager = ({ idBlog }) => {
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState(null);
  const [blogTags, setBlogTags] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let blogResponse = null;
      let blogData = null;
      let blogTagResponse = null;
      let blogTagData = null;

      if (idBlog !== null){
        blogResponse = await fetch(`/api/blogs/${idBlog}`);
      }
      blogTagResponse = await fetch(`/api/blogtags`);
      console.log(blogResponse)
     if ((blogResponse !==null && !blogResponse.ok) | (blogTagResponse !==null  && !blogTagResponse.ok)) {
        console.log('error');
        return;
      }
      if (blogResponse !== null){
        blogData = await blogResponse.json();
      }
      if (blogTagResponse !== null){
        blogTagData = await blogTagResponse.json();
      }
    return [blogData,blogTagData];
    }

    fetchData().then((data) =>{
        setBlog(data[0])
        setBlogTags(data[1])
        setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, [,idBlog]);

 
  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading style-center">
        <div className="section-heading__title mb-50" >
          <h2>{blog !== null ? "modifica blog" : "inserisci nuovo blog"}</h2>
        </div>
      </div>
    <div className="row align-items-center justify-content-center mb-50">
    <div className="col-1">
        <Link to="/admin">
            <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
            </button>
        </Link>
    </div>
    </div>
    <div className="row align-items-center justify-content-center m-3">
      <div className="col-12 section-bg">
        <BlogForm blog={blog} blogtags={blogTags}/>
       </div>
       </div>
       </section> 
  );
};

export default BlogManager;
